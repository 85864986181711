export enum NGRXPRLayoutStateType {
  setStepType = 'Set Step Type',
  setViewMode = 'Set View Mode',
  setSurveyOn = 'Set Survey On',
}

export enum NGRXPRTemplateCycleStateType {
  setTemplateCycle = 'Set Template Cycle',
  setTimeEntity = 'Set Time Entity',
  setDuration = 'Set Duration',
  addStep = 'Add Step',
  removeStep = 'Remove Step',

  addStepToDB = 'Add Step To DB',
  removeStepFromDB = 'Remove Step From DB',

  deleteTemplate = 'Delete Template',
  deleteTemplateFromDB = 'Delete Template From DB',

  initPRTemplate = 'Init PR Template',
}

export enum NGRXPRCycleStateType {
  setCycle = 'Set Cycle',
}

export enum NGRXPRStateSource {
  PRLayoutStateSource = 'PR Layout State Source',
  PRTemplateCycleStateSource = 'PR Template Cycle State Source',
  PRCycleStateSource = 'PR Cycle State Source',
  removeAll = 'Remove All',
}
