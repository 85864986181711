import { createReducer, on } from '@ngrx/store';
import { LoaderActions } from './loader.actions';

const initialState: boolean = false;

export const loaderReducer = createReducer(
  initialState,

  on(LoaderActions.setVisibility, (_, { visible }) => visible),
);
