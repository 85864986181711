import { createAction, createActionGroup, props } from '@ngrx/store';
import {
  PRCycleStepType,
  PreviewStepMode,
  SurveyOn,
} from 'src/core/enums/pr-layout.enum';
import {
  PRTemplateCycle,
  ReviewCycle,
} from 'src/core/interfaces/review-cycle.interface';
import {
  NGRXPRCycleStateType,
  NGRXPRLayoutStateType,
  NGRXPRStateSource,
  NGRXPRTemplateCycleStateType,
} from './performance-review-action.types';

export const PRLayoutActions = createActionGroup({
  source: NGRXPRStateSource.PRLayoutStateSource,
  events: {
    [NGRXPRLayoutStateType.setStepType]: props<{ stepType: PRCycleStepType }>(),
    [NGRXPRLayoutStateType.setViewMode]: props<{ mode: PreviewStepMode }>(),
    [NGRXPRLayoutStateType.setSurveyOn]: props<{ surveyOn: SurveyOn }>(),
  },
});

export const PRTemplateCycleActions = createActionGroup({
  source: NGRXPRStateSource.PRTemplateCycleStateSource,
  events: {
    [NGRXPRTemplateCycleStateType.setTemplateCycle]: props<{
      cycle: PRTemplateCycle;
    }>(),
    [NGRXPRTemplateCycleStateType.setTimeEntity]: props<{
      entity: { field: string; value: string };
    }>(),
    [NGRXPRTemplateCycleStateType.setDuration]: props<{
      duration: number;
    }>(),

    [NGRXPRTemplateCycleStateType.addStep]: props<{
      stepType: PRCycleStepType;
    }>(),
    [NGRXPRTemplateCycleStateType.addStepToDB]: props<{
      stepType: PRCycleStepType;
    }>(),
    [NGRXPRTemplateCycleStateType.removeStep]: props<{
      stepType: PRCycleStepType;
    }>(),
    [NGRXPRTemplateCycleStateType.removeStepFromDB]: props<{
      stepType: PRCycleStepType;
    }>(),

    [NGRXPRTemplateCycleStateType.deleteTemplate]: props<{
      templateId: string;
    }>(),
    [NGRXPRTemplateCycleStateType.deleteTemplateFromDB]: props<{
      templateId: string;
    }>(),
  },
});

export const PRCycleActions = createActionGroup({
  source: NGRXPRStateSource.PRCycleStateSource,
  events: {
    [NGRXPRCycleStateType.setCycle]: props<{ cycle: ReviewCycle }>(),
  },
});

export const initPRTemplate = createAction(NGRXPRTemplateCycleStateType.initPRTemplate);
export const removeAllPRCycles = createAction(NGRXPRStateSource.removeAll);
export const deletePRTemplate = createAction(
  NGRXPRTemplateCycleStateType.deleteTemplate,
);
