import { createReducer, on } from '@ngrx/store';
import {
  PRCycleStepType,
  PreviewStepMode,
  SurveyOn,
} from 'src/core/enums/pr-layout.enum';
import {
  PRTemplateCycle,
  ReviewCycle,
} from 'src/core/interfaces/review-cycle.interface';
import {
  PRCycleActions,
  PRLayoutActions,
  PRTemplateCycleActions,
} from './performance-review.actions';

export interface PRLayoutState {
  stepType: PRCycleStepType;
  mode: PreviewStepMode;
  surveyOn: SurveyOn;
  prFetched: boolean;
  prTemplateFetched: boolean;
}

const initialLayoutState: PRLayoutState = {
  stepType: null,
  mode: PreviewStepMode.Details,
  surveyOn: SurveyOn.Questions,
  prFetched: false,
  prTemplateFetched: false,
};

export interface PRCycleState {
  userId: string;
  cycle: ReviewCycle;
  templateId: string;
}

const initialCycleState: ReviewCycle = null;
const today = new Date();
export const initialTemplateCycleState: PRTemplateCycle = {
  _id: null,
  ISOStartDate: new Date(),
  ISOEndDate: new Date(today.setDate(today.getDate() + 180)),
  duration: 14,
  assessments: [],
  steps: [],
  createdAt: null,
  organizationId: '',
  updatedAt: null,
};

export const prLayoutReducer = createReducer(
  initialLayoutState,

  on(PRLayoutActions.setStepType, (state, { stepType }) => ({
    ...state,
    stepType,
  })),

  on(PRLayoutActions.setViewMode, (state, { mode }) => ({
    ...state,
    mode,
  })),

  on(PRLayoutActions.setSurveyOn, (state, { surveyOn }) => ({
    ...state,
    surveyOn,
  })),
);

export const prTemplateCycleReducer = createReducer(
  initialTemplateCycleState,

  on(PRTemplateCycleActions.setTemplateCycle, (_, { cycle }) => cycle),

  on(PRTemplateCycleActions.addStep, (state, { stepType }) => ({
    ...state,
    steps: Array.from(new Set([...state.steps, stepType])),
  })),

  on(PRTemplateCycleActions.removeStep, (state, { stepType }) => ({
    ...state,
    steps: state.steps.filter((step) => step !== stepType),
  })),

  on(PRTemplateCycleActions.setDuration, (state, { duration }) => ({
    ...state,
    duration,
  })),

  on(PRTemplateCycleActions.setTimeEntity, (state, { entity }) => ({
    ...state,
    [entity.field]: entity.value,
  })),

  on(PRTemplateCycleActions.deleteTemplate, () => initialTemplateCycleState),
);

export const prCycleReducer = createReducer(
  initialCycleState,

  on(PRCycleActions.setCycle, (_, { cycle }) => cycle),
);
