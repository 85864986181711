import { IconType } from './icon.enum';

export enum PreviewStepMode {
  Survey = 'assessments',
  Details = 'details',
}

export enum PRCycleStepType {
  SelfAssessment = 'self-assessment',
  ManagerAssessment = 'manager-assessment',
  PeerReview = 'peer-review',
  PerformanceDiscussions = 'performance-discussions',
}

export enum SurveyOn {
  Questions = 'questions',
  Skills = 'skills',
}

export const StepTitles = {
  [PRCycleStepType.SelfAssessment]: 'Self assessment',
  [PRCycleStepType.ManagerAssessment]: 'Manager assessment',
  [PRCycleStepType.PeerReview]: 'Peer Review',
  [PRCycleStepType.PerformanceDiscussions]: 'Performance discussions',
};

export const StepDescriptions = {
  [PRCycleStepType.SelfAssessment]: `In this step, the reviewed employee completes two surveys evaluating the employee's achievements, progress, and areas for improvement. This feedback is crucial for setting future goals and provides valuable insights for the overall evaluation process.`,
  [PRCycleStepType.ManagerAssessment]: `In this step, the manager completes three surveys evaluating the employee's achievements, progress, and areas for improvement. This feedback is crucial for setting future goals and provides valuable insights for the overall evaluation process.`,
  [PRCycleStepType.PeerReview]: `In this step, some colleagues from employee's team will submit a short feedback on employee's performance`,
  [PRCycleStepType.PerformanceDiscussions]: `In this step, the manager and employee engage in a performance discussion. They review feedback, achievements, and areas for improvement. This conversation helps set future goals and ensures alignment with team and company objectives. It is crucial for ongoing development and success.`,
};

export const StepIcons = {
  [PRCycleStepType.SelfAssessment]: IconType['user-speak'],
  [PRCycleStepType.ManagerAssessment]: IconType['user-check'],
  [PRCycleStepType.PeerReview]: IconType['user-check'],
  [PRCycleStepType.PerformanceDiscussions]: IconType['call-chat'],
};

export const ActionIcon = {
  [PRCycleStepType.SelfAssessment]: {
    name: IconType['info-square'],
    label: 'Info',
  },
  [PRCycleStepType.ManagerAssessment]: {
    name: IconType['info-square'],
    label: 'Info',
  },
  [PRCycleStepType.PeerReview]: {
    name: IconType['info-square'],
    label: 'Info',
  },
  [PRCycleStepType.PerformanceDiscussions]: {
    name: IconType['calendar-plus'],
    label: 'Book',
  },
};
