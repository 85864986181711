import { createReducer, on } from '@ngrx/store';
import { CalendarEvent } from 'src/core/interfaces/event.interface';
import { nanoid } from 'nanoid';
import {
  SchedulerActions,
  schedulerActionRemoveAll,
} from './calendar-event.actions';

const initialState: CalendarEvent[] = [];

export const schedulerReducer = createReducer(
  initialState,

  on(SchedulerActions.loadSchedulerEvents, (_, { events }) => {
    return events;
  }),

  on(SchedulerActions.addEvent, (state, { event }) => [
    ...state,
    { ...event, _id: nanoid(9) },
  ]),

  on(SchedulerActions.updateEvent, (state, { event }) => {
    const index = state.findIndex((e) => e._id === event._id);

    return [...state.slice(0, index), { ...event }, ...state.slice(index + 1)];
  }),

  on(SchedulerActions.removeEvent, (state, { eventId }) =>
    state.filter((event) => event._id != eventId),
  ),

  on(schedulerActionRemoveAll, () => initialState),
);
