export enum NGRXLayoutType {
  toggleSideNavBar = 'Toggle Side Nav Bar',
  toggleCareerPathBar = 'Toggle Career Path Bar',
}

export enum NGRXLayoutSource {
  layoutSource = 'Layout Source',
}

export interface LayoutState {
  isNavbarOpen: boolean;
  isCareerPathOpen: boolean;
}
