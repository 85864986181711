import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { CalendarEvent } from '../interfaces/event.interface';
import { UserGoal } from '../interfaces/user/goal.interface';
import { AuthState, authModeReducer, authReducer } from './auth/auth.reducers';
import { schedulerReducer } from './calendar-event/calendar-event.reducers';
import {
  FeedbackTemplateState,
  feedbackTemplateReducer,
} from './feedback-template/feedback-template.reducers';
import { FeedbackState, feedbackReducer } from './feedback/feedback.reducers';
import { goalsReducer } from './goals/goals.reducers';
// import { hydrationMetaReducer } from './hydration/hydration.reducer';
import { AuthMode } from '../enums/permissions.enum';
import { AppNotification } from '../interfaces/notification.interface';
import { Objective } from '../interfaces/objective.interface';
import { LayoutState } from './layout/layout-action.types';
import { layoutReducer } from './layout/layout.reducers';
import { loaderReducer } from './loader/loader.reducers';
import {
  ManagerDashboardState,
  managerDashboardReducer,
} from './manager-dashboard/manager-dashboard.reducers';
import { MeetingsState, meetingsReducer } from './meetings/meeting.reducers';
import { appNotificationsReducer } from './notifications/notifications.reducers';
import { objectivesReducer } from './objectives/objectives.reducers';
import {
  OrganizationState,
  organizationReducer,
} from './organization/organization.reducers';
import {
  PRLayoutState,
  prCycleReducer,
  prLayoutReducer,
  prTemplateCycleReducer,
} from './performance-review/performance-review.reducers';
import { TeamSate, teamsReducer } from './team/team.reducers';
import { PRTemplateCycle, ReviewCycle } from '../interfaces/review-cycle.interface';

enum AppStateEntities {
  authStore = 'authStore',
  authModeStore = 'authModeStore',
  schedulerStore = 'schedulerStore',
  goalsStore = 'goalsStore',
  objectivesStore = 'objectivesStore',
  teamStore = 'teamStore',
  meetingStore = 'meetingStore',
  loaderStore = 'loaderStore',
  layoutStore = 'layoutStore',
  organizationStore = 'organizationStore',
  feedbackStore = 'feedbackStore',
  feedbackTemplateStore = 'feedbackTemplateStore',
  notificationsStore = 'notificationsStore',
  managerDashboardStore = 'managerDashboardStore',
  prLayoutStore = 'prLayoutStore',
  prCycleStore = 'prCycleStore',
  prTemplateCycleStore = 'prTemplateCycleStore',
}

interface AppState {
  [AppStateEntities.authStore]: AuthState;
  [AppStateEntities.authModeStore]: AuthMode;
  [AppStateEntities.schedulerStore]: CalendarEvent[];
  [AppStateEntities.goalsStore]: UserGoal[];
  [AppStateEntities.objectivesStore]: Objective[];
  [AppStateEntities.teamStore]: TeamSate;
  [AppStateEntities.meetingStore]: MeetingsState;
  [AppStateEntities.loaderStore]: boolean;
  [AppStateEntities.layoutStore]: LayoutState;
  [AppStateEntities.organizationStore]: OrganizationState;
  [AppStateEntities.feedbackStore]: FeedbackState;
  [AppStateEntities.feedbackTemplateStore]: FeedbackTemplateState;
  [AppStateEntities.notificationsStore]: AppNotification[];
  [AppStateEntities.managerDashboardStore]: ManagerDashboardState;
  [AppStateEntities.prLayoutStore]: PRLayoutState;
  [AppStateEntities.prCycleStore]: ReviewCycle;
  [AppStateEntities.prTemplateCycleStore]: PRTemplateCycle;
}

export const reducers: ActionReducerMap<AppState> = {
  [AppStateEntities.authStore]: authReducer,
  [AppStateEntities.authModeStore]: authModeReducer,
  [AppStateEntities.schedulerStore]: schedulerReducer,
  [AppStateEntities.goalsStore]: goalsReducer,
  [AppStateEntities.objectivesStore]: objectivesReducer,
  [AppStateEntities.teamStore]: teamsReducer,
  [AppStateEntities.meetingStore]: meetingsReducer,
  [AppStateEntities.loaderStore]: loaderReducer,
  [AppStateEntities.layoutStore]: layoutReducer,
  [AppStateEntities.organizationStore]: organizationReducer,
  [AppStateEntities.feedbackStore]: feedbackReducer,
  [AppStateEntities.feedbackTemplateStore]: feedbackTemplateReducer,
  [AppStateEntities.notificationsStore]: appNotificationsReducer,
  [AppStateEntities.managerDashboardStore]: managerDashboardReducer,
  [AppStateEntities.prLayoutStore]: prLayoutReducer,
  [AppStateEntities.prCycleStore]: prCycleReducer,
  [AppStateEntities.prTemplateCycleStore]: prTemplateCycleReducer,
};

export const metaReducers: MetaReducer[] = [
  // hydrationMetaReducer
];

export { AppState, AppStateEntities };
