import { createReducer, on } from '@ngrx/store';
import { Objective } from 'src/core/interfaces/objective.interface';
import { ObjectivesActions, removeAllObjectives } from './objectives.actions';

const initialState: Objective[] = [];

export const objectivesReducer = createReducer(
  initialState,

  /* DB actions */
  on(ObjectivesActions.loadFromDB, (state, { objectives }) =>
    [...state, ...objectives].sort(
      (a, b) =>
        new Date(b.ISOStartDate).getTime() - new Date(a.ISOStartDate).getTime(),
    ),
  ),

  /* Regular actions */
  on(ObjectivesActions.add, (state, { objective }) =>
    [...state, objective].sort(
      (a, b) =>
        new Date(b.ISOStartDate).getTime() - new Date(a.ISOStartDate).getTime(),
    ),
  ),
  on(ObjectivesActions.addMany, (state, { objectives }) =>
    [...state, ...objectives].sort(
      (a, b) =>
        new Date(b.ISOStartDate).getTime() - new Date(a.ISOStartDate).getTime(),
    ),
  ),
  on(ObjectivesActions.update, (state, { updatedObjective }) =>
    state.map((objective) => {
      if (objective._id === updatedObjective._id) {
        return updatedObjective;
      }

      return objective;
    }),
  ),

  on(ObjectivesActions.updateField, (state, { entityId, updatedField }) =>
    state.map((goal) => {
      if (goal._id === entityId) {
        return {
          ...goal,
          [updatedField.field]: updatedField.value,
        };
      }

      return goal;
    }),
  ),
  on(ObjectivesActions.deleteEntity, (state, { entityId }) =>
    state.filter((objective) => objective._id !== entityId),
  ),

  /* Resources */
  on(ObjectivesActions.addResources, (state, { entityId, resources }) =>
    state.map((entity) =>
      entity._id !== entityId ? entity : { ...entity, resources },
    ),
  ),
  on(ObjectivesActions.removeResource, (state, { entityId, resourceId }) =>
    state.map((entity) =>
      entity._id !== entityId
        ? entity
        : {
            ...entity,
            resources: entity.resources.filter(
              (resource) => resource._id !== resourceId,
            ),
          },
    ),
  ),

  /* Clear store */
  on(removeAllObjectives, () => initialState),
);
