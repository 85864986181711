import { createReducer, on } from '@ngrx/store';
import { AppNotification } from 'src/core/interfaces/notification.interface';
import {
  NotificationsActions,
  removeAllNotifications,
} from './notifications.actions';

const initialState: AppNotification[] = [];

export const appNotificationsReducer = createReducer(
  initialState,

  on(
    NotificationsActions.loadNotificationsFromDB,
    (state, { notifications }) => [...state, ...notifications],
  ),

  on(NotificationsActions.markAsRead, (state, { notificationId, read }) =>
    state.map((notification) => {
      if (notification._id !== notificationId) {
        return notification;
      }

      return {
        ...notification,
        read,
      };
    }),
  ),

  on(NotificationsActions.addNotification, (state, { notification }) => [
    ...state,
    notification,
  ]),

  on(removeAllNotifications, () => initialState),
);
