import { createReducer, on } from '@ngrx/store';
import { TeamData, TeamMember } from 'src/core/interfaces/team/team.interface';
import { TeamsActions, removeAllTeams } from './team.actions';

export interface TeamSate {
  teams: TeamData[];
  people: TeamMember[];
}

const initialState: TeamSate = {
  teams: [],
  people: [],
};

function uniqueMembers(teams: TeamData[]): TeamMember[] {
  const uniques: TeamMember[] = [];

  teams.forEach((team) => {
    team.members.forEach((member) => {
      if (!uniques.find((person) => person._id === member._id)) {
        uniques.push(member);
      }
    });
  });

  return uniques;
}

export const teamsReducer = createReducer(
  initialState,

  on(TeamsActions.createTeam, (state, { team }) => ({
    ...state,
    teams: [...state.teams, team],
  })),

  on(TeamsActions.loadTeamsFromDB, (state, { teams }) => ({
    ...state,
    teams,
    people: uniqueMembers(teams),
  })),

  on(TeamsActions.loadTeams, (state, { teams }) => ({
    ...state,
    teams,
    people: uniqueMembers(teams),
  })),

  on(TeamsActions.updateTeam, (state, { updatedTeam }) => {
    const teams = state.teams.map((team) => {
      if (team._id === updatedTeam._id) {
        return updatedTeam;
      }

      return team;
    });

    return {
      teams,
      people: uniqueMembers(teams),
    };
  }),
  on(TeamsActions.deleteTeam, (state, { teamId }) => {
    const teams = state.teams.filter((team) => team._id !== teamId);

    return {
      teams,
      people: uniqueMembers(teams),
    };
  }),

  on(removeAllTeams, () => initialState),
);
