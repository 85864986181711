export enum NGRXNotificationsType {
  initNotificationsStore = 'Init Notifications Store',

  loadNotificationsFromDB = 'Load Notifications From DB',
  markAsReadInDB = 'Mark As Read In DB',
  removeAllFromDB = 'Remove All From DB',
  fetchFromDB = 'Fetch From DB',

  markAsRead = 'Mark As Read',
  addNotification = 'Add Notification',
  removeAll = 'Remove All',
}

export enum NGRXNotificationsSource {
  NotificationsSource = 'Notifications Source',
}
