import { createReducer, on } from '@ngrx/store';
import { Meeting } from 'src/core/interfaces/meeting.interface';
import { MeetingActions, removeAllMeetings } from './meeting.actions';

export interface MeetingsState {
  meetings: Meeting[];
  selectedMeeting: Meeting;
}

const initialState: MeetingsState = {
  meetings: [],
  selectedMeeting: null,
};

export const meetingsReducer = createReducer(
  initialState,

  on(MeetingActions.loadMeetings, (_, { meetings }) => {
    return {
      meetings: meetings,
      selectedMeeting: meetings[0],
    };
  }),

  on(MeetingActions.addMeeting, (state, { meeting }) => ({
    meetings: [...state.meetings, meeting],
    selectedMeeting: meeting,
  })),

  on(MeetingActions.setSelectedMeeting, (state, { meetingId }) => {
    return {
      meetings: state.meetings,
      selectedMeeting: state.meetings.find((item) => item._id === meetingId),
    };
  }),

  on(MeetingActions.updateMeeting, (state, { meeting }) => {
    const index = state.meetings.findIndex((item) => item._id === meeting._id);

    return {
      meetings: [
        ...state.meetings.slice(0, index),
        meeting,
        ...state.meetings.slice(index + 1),
      ],
      selectedMeeting:
        state.selectedMeeting._id === meeting._id
          ? meeting
          : state.selectedMeeting,
    };
  }),

  on(MeetingActions.removeMeeting, (state, { meetingId }) => {
    const filteredMeetings = state.meetings.filter(
      (item) => item._id !== meetingId,
    );

    return {
      meetings: filteredMeetings,
      selectedMeeting: filteredMeetings[0] || null,
    };
  }),

  on(
    MeetingActions.removeMeetingQuestion,
    (state, { meetingId, meetingQuestionId }) => {
      const alteredMeetings = state.meetings.map((item) => {
        if (item._id === meetingId) {
          return {
            ...item,
            meetingQuestions: item.meetingQuestions.filter(
              (item) => item._id !== meetingQuestionId,
            ),
          };
        }

        return item;
      });

      return {
        meetings: alteredMeetings,
        selectedMeeting: alteredMeetings.find((item) => item._id === meetingId),
      };
    },
  ),

  on(removeAllMeetings, () => initialState),
);
