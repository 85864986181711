import { createAction, createActionGroup, props } from '@ngrx/store';
import { AppNotification } from 'src/core/interfaces/notification.interface';

import {
  NGRXNotificationsSource,
  NGRXNotificationsType,
} from './notifications-action.types';

export const NotificationsActions = createActionGroup({
  source: NGRXNotificationsSource.NotificationsSource,

  events: {
    [NGRXNotificationsType.loadNotificationsFromDB]: props<{
      notifications: AppNotification[];
    }>(),
    [NGRXNotificationsType.markAsReadInDB]: props<{
      notificationId: string;
      read: boolean;
    }>(),
    [NGRXNotificationsType.fetchFromDB]: props<{ notificationIds: string[] }>(),

    [NGRXNotificationsType.addNotification]: props<{
      notification: AppNotification;
    }>(),
    [NGRXNotificationsType.markAsRead]: props<{
      notificationId: string;
      read: boolean;
    }>(),
  },
});

export const initNotificationsStore = createAction(
  NGRXNotificationsType.initNotificationsStore,
);

export const removeAllNotifications = createAction(
  NGRXNotificationsType.removeAll,
);

export const removeAllNotificationsFromDB = createAction(
  NGRXNotificationsType.removeAllFromDB,
);
