import { createReducer, on } from '@ngrx/store';
import { Metrics } from 'src/core/enums/metrics.enum';
import {
  UserGoalsStatusMetric,
  UserLastMeetingMetric,
} from 'src/core/interfaces/user/metrics.interface';
import { AuthService } from 'src/core/services/auth.service';
import {
  ManagerDashboardActions,
  removeAllManagerDashboards,
} from './manager-dashboard.actions';

export interface ManagerDashboardState {
  [Metrics.NRGoalsPerTeam]: {
    data: number[];
    labels: string[];
  };
  [Metrics.GoalsStatusPerUser]: UserGoalsStatusMetric[];
  [Metrics.LastMeetingPerUser]: UserLastMeetingMetric[];
}

const initialState: ManagerDashboardState = {
  [Metrics.NRGoalsPerTeam]: {
    data: [],
    labels: [],
  },
  [Metrics.GoalsStatusPerUser]: [],
  [Metrics.LastMeetingPerUser]: [],
};

export const managerDashboardReducer = createReducer(
  initialState,

  on(
    ManagerDashboardActions.loadGoalsStatusPerUserFromDB,
    (state, { aggregation }) => {
      return {
        ...state,
        [Metrics.GoalsStatusPerUser]: aggregation,
      };
    },
  ),

  on(
    ManagerDashboardActions.loadLastMeetingPerUserFromDB,
    (state, { aggregation, teammates }) => {
      const selfId = AuthService.getUser()?._id;

      return {
        ...state,
        [Metrics.LastMeetingPerUser]: aggregation.map((meeting) => {
          const teammate = teammates.find(
            (teammate) =>
              teammate._id !== selfId &&
              meeting.participants.includes(teammate._id),
          );

          return {
            ...meeting,
            ...teammate,
            lastMet: meeting.ISODate,
          };
        }),
      };
    },
  ),

  on(removeAllManagerDashboards, () => initialState),
);
