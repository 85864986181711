import { createReducer, on } from '@ngrx/store';
import { TeamMember } from 'src/core/interfaces/team/team.interface';
import {
  getAllEmployees,
  loadOrganization,
  removeAllEmployees,
} from './organization.actions';

export interface OrganizationState {
  name?: string;
  employees: TeamMember[];
}

const initialState: OrganizationState = {
  name: '',
  employees: [],
};

export const organizationReducer = createReducer(
  initialState,

  on(loadOrganization, (state, { organization }) => ({
    ...state,
    name: organization.name,
    employees: organization.employees,
  })),
  on(getAllEmployees, (state) => state),
  on(removeAllEmployees, () => initialState),
);
